<template>
    <div class="page">

        <div class="page__header">
            <div class="page__info">
                <div class="page__title">Производители</div>
                <div class="page__desc">Список производителей</div>
            </div>
            <div class="page__actions">
                <router-link
                        :to="{ name: 'CatalogManufacturersCreate' }">
                    <button type="button" class="btn btn-success">Добавить</button>
                </router-link>
            </div>
        </div>

        <div v-if="manufacturers != null"
                class="page__content">

            <paginate
                :page-count="manufacturers.pageLast"
                :click-handler="paginator"
                v-model="page"
                :prev-text="'Prev'"
                :next-text="'Next'"
                :container-class="'pagination'"
                :page-class="'page-item'"
                :prev-class="'page-item'"
                :next-class="'page-item'"
                :page-link-class="'page-link'"
                :prev-link-class="'page-link'"
                :next-link-class="'page-link'"/>

            <div class="table-responsive">
                <table class="table table-striped">
                    <tr>
                        <th>Название</th>
                        <th>Страна</th>
                        <th>Действие</th>
                    </tr>
                    <tbody v-if="!this.$store.state.loading.showLoading">
                        <tr v-for="(manufacturer, index) in manufacturers.items"
                            v-bind:key="manufacturer.id">
                            <td>{{ manufacturer.title }}</td>
                            <td>{{ manufacturer.country }}</td>
                            <td>
                                <router-link
                                        :to="{ name: 'CatalogManufacturersUpdate', params: {id: manufacturer.id } }"
                                        tag="input"
                                        type="button"
                                        class="btn btn-sm btn-warning"
                                        value="Изменить"/>
                                <input
                                        @click="deleteManufacturer(index)"
                                        type="button"
                                        class="btn btn-sm btn-danger"
                                        value="Удалить">
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div>
</template>


<script>
    import { mapActions } from 'vuex'
    import paginate from 'vuejs-paginate'
    import router from '@/router'

    export default {
        name: 'CatalogManufacturers',
        components: {
            paginate,
        },
        data() {
            return {
                page: this.$route.query.page != null ? parseInt(this.$route.query.page) : 1
            }
        },
        computed: {
            manufacturers() {
                return this.$store.state.manufacturers.manufacturers
            }
        },
        methods: {
            ...mapActions([
                'catalogManufacturers',
                'catalogManufacturersDelete'
            ]),
            async getManufacturers() {
                await this.catalogManufacturers({
                    page: this.page,
                    limit: 10
                })
            },
            async deleteManufacturer(index) {
                if (confirm('Вы уверены, что хотите удалить?')) {
                    let manufacturer = this.manufacturers.splice(index, 1)[0];

                    await this.catalogManufacturersDelete({
                        id: manufacturer.id
                    })
                }
            },
            paginator(pageNum) {
                router.push({ name: 'CatalogManufacturers', query: { page: pageNum }});
            }
        },
        created() {
            this.getManufacturers();
        },
        watch: {
            $route() {
                this.getManufacturers();
            }
        },
    }
</script>

<style lang="scss" scoped>
    .btn {
        margin-right: 10px;
    }
</style>